<template>
  <div class="edit-view">
    <page-title>
        <el-button @click="$router.go(-1)" :disabled="saving" >取消</el-button>
        <!-- <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button> -->
        <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">
        <div class="edit-view__content-section">
            <el-form :model="formData" :rules="rules" ref="form" label-width="140px" label-position="right" size="small" style="max-width: 800px">
            <el-form-item
                label="项目"
                prop="projectId">
                <project-select v-model="formData.projectId">
                </project-select>
            </el-form-item>
            <el-form-item
                prop="name"
                label="名称">
                <el-input
                v-model="formData.name"
                :disabled="!canEdit"
                ></el-input>
            </el-form-item>
            <el-form-item
                prop="pic"
                label="图片">
                <object-upload
                v-model="formData.pic[0]"
                :disabled="!canEdit"
                compress
                width="3000"
                height="3000"
                ></object-upload>
                <div class="tip">支持JPG、PNG、GIF、MP4，推荐长宽比1:1</div>
            </el-form-item>
            <el-form-item
                prop="picPattern"
                label="主图模式">
                <div class="img-view-wrap">
                <div class="img-view-li">
                    <el-input
                    v-model="formData.picPattern"
                    placeholder="需要系统随机生成动态资产图片时填写"
                    :disabled="!canEdit"
                    @change="picPatternChange"
                    ></el-input>
                    <div class="tip">动态资源地址,动态序号使用'{}'替代，比如：http://xxx.com/mouse-01-{}.jpg</div>
                    <el-alert
                    type="warning"
                    description="暂时只用于批量生成生肖藏品资源，其他藏品请勿填写任何内容"
                    show-icon
                    :closable="false"
                    ></el-alert>
                </div>
                <div class="img-view-li">
                    <el-image
                    style="width: 100px; height: 100px"
                    :src="picViewUrl"
                    fit="cover"
                    :preview-src-list="[picViewUrl]"
                    alt="预览图"
                    ></el-image>
                </div>
                </div>
            </el-form-item>
            <el-form-item prop="model3d" label="3D模型" >
                <model-upload
                :limit="1"
                v-model="formData.model3d"
                :customUrl="customUrl"
                accept="application/zip"
                format="json"
                single
                ></model-upload>
                <div class="tip">请将FBX文件与贴图打包成zip压缩包上传</div>
            </el-form-item>
            <el-form-item prop="minterId" label="铸造者">
                <minter-select
                v-model="formData.minterId"
                @detail="onMinterDetail"
                :disabled="!canEdit"
                :projectId="formData.projectId"
                ></minter-select>
            </el-form-item>
            <el-form-item prop="category" label="分类">
                <el-select
                v-model="formData.category"
                :disabled="!canEdit"
                >
                <el-option
                    v-for="item in categories"
                    :label="item"
                    :value="item"
                    :key="item"
                ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="collectionCategoryId" label="筛选分类"  >
                <el-select
                v-model="formData.collectionCategoryId"
                clearable=""
                >
                <el-option
                    v-for="item in collectionCategories"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                    @click.native="changeCategories(item)"
                ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="collectionCategoryChildId" label="筛选子分类"  >
                <el-select
                v-model="formData.collectionCategoryChildId"
                clearable=""
                multiple
                >
                <el-option
                    v-for="item in collectionChildCategories"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                prop="detail"
                label="详情"
                style="width: calc(100vw - 450px)">
                <rich-text v-model="formData.detail"></rich-text>
            </el-form-item>
            <el-form-item
                prop="properties"
                label="特性"
                style="width: calc(100vw - 450px)"
                size="mini">
                <el-table :data="formData.properties">
                <el-table-column
                    prop="name"
                    label="名称"
                >
                    <template v-slot="{ row }">
                    <el-input
                        v-model="row.name"
                        placeholder="20字以内"
                        maxlength="20"
                    ></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="value"
                    label="内容"
                >
                    <template v-slot="{ row }">
                    <el-input
                        v-model="row.value"
                        placeholder="20字以内"
                        maxlength="20"
                    ></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    width="80"
                    align="center"
                >
                    <template v-slot="{ row, $index }">
                    <el-button
                        type="danger"
                        plain
                        size="mini"
                        @click="delProperty($index)"
                    >
                        删除
                    </el-button>
                    </template>
                </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item>
                <el-button
                size="mini"
                @click="addProperty"
                :disabled="!canEdit"
                > 添加特性 </el-button>
            </el-form-item>
            <el-form-item
                label="特权"
                prop="privileges"
                style="width: calc(100vw - 450px)"
            >
                <el-table :data="privilegeOptions">
                <el-table-column
                    prop="name"
                    label="可选特权"
                    width="150"
                ></el-table-column>
                <el-table-column prop="description"></el-table-column>
                <el-table-column
                    width="155"
                    align="right"
                >
                    <template v-slot="{ row, $index }">
                    <el-button
                        size="mini"
                        v-if="!row.added"
                        @click="addPrivilege(row, $index)"
                    >
                        添加
                    </el-button>
                    <el-button
                        size="mini"
                        v-if="!!row.added"
                        plain
                        @click="editPrivilege(row, $index)"
                    >
                        编辑
                    </el-button>
                    <el-button
                        size="mini"
                        v-if="!!row.added"
                        type="danger"
                        plain
                        @click="delPrivilege(row, $index)"
                    >
                        删除
                    </el-button>
                    </template>
                </el-table-column>
                </el-table>
            </el-form-item>
            <!-- <el-form-item prop="type" label="类型">
                            <el-select v-model="formData.type" clearable filterable placeholder="请选择">
                                <el-option
                                    v-for="item in typeOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="source" label="来源">
                            <el-select v-model="formData.source" clearable filterable placeholder="请选择">
                                <el-option
                                    v-for="item in sourceOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item> -->
            <el-form-item
                prop="price"
                label="价格"
            >
                <el-input-number
                type="number"
                v-model="formData.price"
                :disabled="!canEdit"
                ></el-input-number>
            </el-form-item>
            <el-form-item
                prop="price"
                label="原价"
            >
                <el-input-number
                type="number"
                v-model="formData.originalPrice"
                :disabled="!canEdit"
                ></el-input-number>
            </el-form-item>
<!--            <el-form-item-->
<!--                prop="royalties"-->
<!--                label="版税(%)"-->
<!--            >-->
<!--                <el-input-number-->
<!--                v-model="formData.royalties"-->
<!--                :min="0"-->
<!--                :max="99"-->
<!--                :disabled="!canEdit"-->
<!--                >-->
<!--                </el-input-number>-->
<!--            </el-form-item>-->
            <el-form-item
                prop="serviceCharge"
                label="手续费(%)"
            >
                <el-input-number
                v-model="formData.serviceCharge"
                :min="0"
                :max="99"
                :disabled="!canEdit"
                >
                </el-input-number>
            </el-form-item>
            <el-form-item
                prop="total"
                label="发行数量"
            >
                <el-input-number
                v-model="formData.total"
                :disabled="!canEdit"
                ></el-input-number>
            </el-form-item>
            <el-form-item
                prop="limitBuyCount"
                label="限制购买数量"
            >
                <el-input-number
                v-model="formData.limitBuyCount"
                :min="1"
                ></el-input-number>
            </el-form-item>
            <el-form-item
                prop="holdDays"
                label="持有多少分钟可售"
            >
                <el-input-number
                v-model="formData.holdDays"
                :min="1"
                ></el-input-number>
            </el-form-item>
            <!-- <el-form-item prop="likes" label="点赞">
                            <el-input-number v-model="formData.likes"></el-input-number>
                        </el-form-item> -->
            <el-form-item
                prop="startTime"
                label="定时发布"
            >
                <el-switch
                v-model="formData.scheduleSale"
                active-text="是"
                inactive-text="否"
                ></el-switch>
                <div
                style="margin-top: 10px"
                v-if="formData.scheduleSale"
                >
                <el-date-picker
                    v-model="formData.startTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="发布时间"
                ></el-date-picker>
                </div>
            </el-form-item>
            <el-form-item
                prop="onShelf"
                label="上架"
                v-if="!formData.scheduleSale"
            >
                <el-switch
                v-model="formData.onShelf"
                active-text="上架"
                inactive-text="下架"
                ></el-switch>
            </el-form-item>

            <el-form-item
                prop="salable"
                label="可售"
            >
                <el-switch
                v-model="formData.salable"
                active-text="可销售"
                inactive-text="仅展示"
                ></el-switch>
            </el-form-item>
            <el-form-item
                prop="onAuction"
                label="寄售开关"
            >
                <el-switch
                v-model="formData.onAuction"
                active-text="开启"
                inactive-text="关闭"
                ></el-switch>
            </el-form-item>
            <el-form-item
                prop="sort"
                label="排序"
            >
                <el-input-number
                v-model="formData.sort"
                :min="0"
                ></el-input-number>
                <div class="tip">数字越大排序越靠前，相同数值按创建时间倒序排列</div>
            </el-form-item>
            <el-form-item class="form-submit">
                <el-button
                @click="onSave"
                :loading="saving"
                type="primary"
                > 保存 </el-button>
                <!-- <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                                删除
                            </el-button> -->
                <el-button
                @click="$router.go(-1)"
                :disabled="saving"
                >取消</el-button>
            </el-form-item>
            </el-form>
        </div>
    </div>

    <el-dialog
      :visible.sync="showPrivilegeEditDialog"
      width="600px"
      :title="privilegeForm.name"
    >
      <el-form
        ref="privilegeForm"
        :model="privilegeForm"
        label-position="right"
        label-width="80px"
        :rules="privelegeRules"
      >
        <el-form-item
          prop="detail"
          label="详细内容"
          v-if="privilegeForm.type === 'text' || privilegeForm.type === 'exchange'"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3 }"
            v-model="privilegeForm.detail"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="detail"
          label="二维码"
          v-if="privilegeForm.type === 'qrcode'"
        >
          <single-upload v-model="privilegeForm.detail"></single-upload>
        </el-form-item>
        <el-form-item
          prop="remark"
          label="说明"
          v-if="privilegeForm.type === 'qrcode' || privilegeForm.type === 'code'"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3 }"
            v-model="privilegeForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="showPrivilegeEditDialog = false">取消</el-button>
        <el-button
          type="primary"
          @click="savePrivilege"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import resolveUrl from 'resolve-url';
import ModelUpload from '../components/ModelUpload.vue';
export default {
    name: 'CollectionEdit',
    components: { ModelUpload },
    watch: {
        'formData.serviceCharge'(newVal, oldVal) {
            let seat = String(newVal).indexOf('.') + 1
            let count = String(newVal).length - seat
            if(count != 1) {
                this.formData.serviceCharge = newVal.toFixed(1)
            }

        }
    },
    created() {
        this.initCategoryList();
        Promise.all([
            new Promise((resolve, reject) => {
                if (this.$route.query.id) {

                    return this.$http
                        .get('collection/get/' + this.$route.query.id)
                        .then(res => {
                            res.properties = res.properties || [];
                            res.privileges = res.privileges || [];
                            console.log(res.collectionCategoryId)


                            if(res.collectionCategoryId.indexOf(",") > 0) {
                                res.collectionCategoryChildId = res.collectionCategoryId.split(',')
                            }
                            // res.collectionCategoryId = res.collectionCategoryId.split(',')

                            this.formData = res;
                            resolve();
                        })
                        .catch(e => {
                            this.$message.error(e.error);
                            resolve();
                        });
                }
                return resolve();
            }),
            this.$http
                .post('/privilegeOption/all', { size: 10000, query: { del: false } }, { body: 'json' })
                .then(res => {
                    this.privilegeOptions = res.content;
                    return Promise.resolve();
                })
        ]).then(() => {
            console.log(this.formData, this.privilegeOptions);
            this.formData.privileges.forEach(p => {
                let idx = this.privilegeOptions.findIndex(i => i.name === p.name);
                if (idx > -1) {
                    this.$set(this.privilegeOptions[idx], 'added', true);
                }
            });

            //init picPattern
            this.picPatternChange();
        });
    },
    computed: {
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    data() {
        return {
            saving: false,
            collectionCategories: [],
            formData: {
                projectId: this.$store.state.projectId,
                onShelf: true,
                salable: true,
                onAuction: false,
                properties: [],
                type: 'DEFAULT',
                source: 'OFFICIAL',
                pic: [],
                scheduleSale: false,
                sort: 0,
                privileges: [],
                picPattern: '',
                collectionCategoryId:null,
                limitBuyCount: 10
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                variableCollectionId: [
                    {
                        required: true,
                        message: '请输入可变的盲盒藏品的ID',
                        trigger: 'blur'
                    }
                ],
                pic: [
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if (!(value instanceof Array)) {
                                    callback(new Error('请上传内容'));
                                    return;
                                } else {
                                    for (let f of value) {
                                        if (!f.url) {
                                            callback(new Error('请上传内容'));
                                            return;
                                        }
                                    }
                                }
                                callback();
                            } else {
                                callback(new Error('请上传内容'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                minter: [
                    {
                        required: true,
                        message: '请输入铸造者',
                        trigger: 'blur'
                    }
                ],
                minterId: [
                    {
                        required: true,
                        message: '请输入铸造者ID',
                        trigger: 'blur'
                    }
                ],
                minterAvatar: [
                    {
                        required: true,
                        message: '请输入铸造者头像',
                        trigger: 'blur'
                    }
                ],
                detail: [
                    {
                        required: true,
                        message: '请输入详情',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请输入类型',
                        trigger: 'blur'
                    }
                ],
                source: [
                    {
                        required: true,
                        message: '请输入来源',
                        trigger: 'blur'
                    }
                ],
                total: [
                    {
                        required: true,
                        message: '请输入发行数量',
                        trigger: 'blur'
                    }
                ],
                limitBuyCount: [
                    {
                        required: true,
                        message: '请输入限制购买数量',
                        trigger: 'blur'
                    }
                ],
                onShelf: [
                    {
                        required: true,
                        message: '请输入上架',
                        trigger: 'blur'
                    }
                ],
                price: [
                    {
                        required: true,
                        message: '请输入价格',
                        trigger: 'blur'
                    }
                ],
                properties: [
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if (!(value instanceof Array)) {
                                    callback(new Error('properties must be array!'));
                                    return;
                                } else {
                                    for (let i = 0; i < value.length; i++) {
                                        if (value[i].name === '' || value[i].name === undefined) {
                                            callback(new Error('请填写名称'));
                                            return;
                                        }
                                        if (value[i].value === '' || value[i].value === undefined) {
                                            callback(new Error('请填写内容'));
                                            return;
                                        }
                                    }
                                }
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                category: [{ required: true, message: '请填写分类' }],
                // royalties: [{ required: true, message: '请填写版税' }],
                serviceCharge: [{ required: true, message: '请填手续费' }],
                projectId: [{ required: true, message: '请选择项目' }]
            },
            typeOptions: [
                { label: '默认', value: 'DEFAULT' },
                { label: '盲盒', value: 'BLIND_BOX' },
                { label: '拍卖', value: 'AUCTION' }
            ],
            sourceOptions: [
                { label: '官方', value: 'OFFICIAL' },
                { label: '用户铸造', value: 'USER' },
                { label: '转让', value: 'TRANSFER' }
            ],
            categories: ['勋章', '数字资产', '数字艺术', '门票', '游戏', '音乐', '使用', '其他'],
            checkCategories: {},
            collectionChildCategories: {},
            privilegeOptions: [],
            showPrivilegeEditDialog: false,
            privilegeForm: {},
            privelegeRules: {
                detail: [{ required: true, message: '请填写内容' }],
                remark: [{ required: true, message: '请填写说明' }]
            },
            customUrl: resolveUrl(this.$baseUrl, 'upload/3dModel'),
            picViewUrl: null
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    // this.formData.collectionCategoryId = this.formData.collectionCategoryId.join(',')
                    this.formData.collectionCategoryId = this.formData.collectionCategoryId && this.formData.collectionCategoryChildId ? this.formData.collectionCategoryChildId.join(',') : this.formData.collectionCategoryId;

                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = {
                ...this.formData,
                collectionCategory: null
            };

            if(this.formData.collectionCategoryId){
                data.collectionCategory = {
                    id: this.formData.collectionCategoryId
                };
            }

            this.saving = true;

            // console.log(this.formData)
            this.$http
                .post(this.formData.id ? '/collection/save' : '/collection/create', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/collection/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        onMinterDetail(e) {
            console.log(e);
            this.$set(this.formData, 'minter', e.nickname);
            this.$set(this.formData, 'minterAvatar', e.avatar);
        },
        addProperty() {
            this.formData.properties.push({
                name: '',
                value: ''
            });
        },
        delProperty(i) {
            this.formData.properties.splice(i, 1);
        },
        addPrivilege(row, i) {
            this.privilegeForm = { ...row };
            this.showPrivilegeEditDialog = true;
            if (this.$refs.privilegeForm) {
                this.$nextTick(() => {
                    this.$refs.privilegeForm.clearValidate();
                });
            }
        },
        editPrivilege(row, i) {
            this.privilegeForm = { ...(this.formData.privileges.find(e => e.name === row.name) || {}) };
            this.showPrivilegeEditDialog = true;
            if (this.$refs.privilegeForm) {
                this.$nextTick(() => {
                    this.$refs.privilegeForm.clearValidate();
                });
            }
        },
        delPrivilege(row, i) {
            let idx = this.formData.privileges.findIndex(e => e.name === row.name);
            if (idx > -1) {
                this.formData.privileges.splice(idx, 1);
            }
            this.$set(this.privilegeOptions[i], 'added', false);
        },
        initCategoryList() {
            const params = {
                page: 0,
                size: 10000,
                sort: 'createdAt,desc',
                search: '',
                query: {
                    del: false,
                    source: 'OFFICIAL',
                    createdAt: '',
                    minterId: '',
                    projectId: this.$store.state.projectId
                }
            };
            this.$http
                .post('collectionCategory/all', params, { body: 'json' })
                .then(res => {
                    this.collectionCategories = res || [];
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        savePrivilege() {
            console.log('保存')
            this.$refs.privilegeForm
                .validate()
                .then(() => {
                    let i = this.formData.privileges.findIndex(e => e.name === this.privilegeForm.name);
                    if (i > -1) {
                        this.$set(this.formData.privileges, i, { ...this.privilegeForm });
                    } else {
                        this.formData.privileges.push({ ...this.privilegeForm });
                    }
                    let ii = this.privilegeOptions.findIndex(i => i.name === this.privilegeForm.name);
                    console.log(ii);
                    this.$set(this.privilegeOptions[ii], 'added', true);
                    this.showPrivilegeEditDialog = false;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        changeCategories(item) {
            this.checkCategories = item;
            this.collectionChildCategories = item.children;
        },
        picPatternChange() {
            let url = this.formData.picPattern;
            if (url != null) {
                url = url.replace(/\{\}/g, '001');
                this.picViewUrl = url;
            } else {
                this.picViewUrl = null;
            }
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-switch__label--left {
    width: 50px;
    text-align: right;
}
.number-percent {
    display: flex;
    align-items: center;
    .percent {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        width: 30px;
        text-align: center;
        line-height: 30px;
        color: @text2;
        font-size: 13px;
    }
}
.tip {
    font-size: 12px;
    color: @text3;
    margin-top: 5px;
}

.img-view-wrap {
    display: flex;

    .img-view-li {
        margin-right: 20px;
    }
}
</style>
